import React, { useState } from "react"
import ChartCard from "../../../../../../components/CustomComposedChart";
import { t } from "i18next";
import { Radio, Select, Tabs } from "antd";

const CashFlowContainer = (props) => {
    let { glContainer } = props;
    const fakeData = require('./fakeData.json')
    const [ loading, setLoading ] = useState(true);
    const [dataType, setDataType] = useState('totalTradeValue')
    let chartWidth = glContainer.width - 100;
    let chartHeight = glContainer.height - 85;
    const [timeType,setTimeType] = useState('day')
    const onChangeStockType = (e) => {
        setLoading(true);
        setDataType(e.target.value);
    };
    const data = {
        'totalTradeValue':{
            key: 'cashflow',
            chartType: "stackBar",
            title: `${t('Phân bố dòng tiền theo ngành')}`,
            data: fakeData[dataType],
            timeType: timeType,
            YAxisUnit: "",
            barColorByValue: true,
            chartStructure: {
                bar: [
                    { dataKey: "bank", name: t('Ngân hàng'), yAxisType: "left", unit: "" },
                    { dataKey: "estate", name: t('Bất động sản'), yAxisType: "left", unit: "" },
                    { dataKey: "finance", name: t('Dịch vụ tài chính'), yAxisType: "left", unit: "" },
                    { dataKey: "energy", name: t('Tài nguyên'), yAxisType: "left", unit: "" },
                    { dataKey: "retail", name: t('Dịch vụ bán lẻ'), yAxisType: "left", unit: "" },
                    { dataKey: "food", name: t('Thực phẩm & đồ uống'), yAxisType: "left", unit: "" },
                    { dataKey: "contruct", name: t('Xây dựng & vật liệu'), yAxisType: "left", unit: "" },
                    { dataKey: "chemistry", name: t('Hóa chất'), yAxisType: "left", unit: "" },
                    { dataKey: "petroleum", name: t('Dầu khí'), yAxisType: "left", unit: "" },
                    { dataKey: "industrial", name: t('Hàng hóa & dịch vụ'), yAxisType: "left", unit: "" },
                    { dataKey: "technology", name: t('Công nghệ'), yAxisType: "left", unit: "" },
                    { dataKey: "services", name: t('Dịch vụ tiện ích'), yAxisType: "left", unit: "" },
                ],
                line: [],
                area: [],
            },
        },
        'totalTrade':{
            key: 'cashflow',
            chartType: "stackBar",
            title: `${t('Tỷ trọng dòng tiền theo ngành')}`,
            data: fakeData[dataType],
            timeType: timeType,
            YAxisUnit: "%",
            barColorByValue: true,
            chartStructure: {
                bar: [
                    { dataKey: "bank", name: t('Ngân hàng'), yAxisType: "left", unit: "%" },
                    { dataKey: "estate", name: t('Bất động sản'), yAxisType: "left", unit: "%" },
                    { dataKey: "finance", name: t('Dịch vụ tài chính'), yAxisType: "left", unit: "%" },
                    { dataKey: "energy", name: t('Tài nguyên'), yAxisType: "left", unit: "%" },
                    { dataKey: "retail", name: t('Dịch vụ bán lẻ'), yAxisType: "left", unit: "%" },
                    { dataKey: "food", name: t('Thực phẩm & đồ uống'), yAxisType: "left", unit: "%" },
                    { dataKey: "contruct", name: t('Xây dựng & vật liệu'), yAxisType: "left", unit: "%" },
                    { dataKey: "chemistry", name: t('Hóa chất'), yAxisType: "left", unit: "%" },
                    { dataKey: "petroleum", name: t('Dầu khí'), yAxisType: "left", unit: "%" },
                    { dataKey: "industrial", name: t('Hàng hóa & dịch vụ'), yAxisType: "left", unit: "%" },
                    { dataKey: "technology", name: t('Công nghệ'), yAxisType: "left", unit: "%" },
                    { dataKey: "services", name: t('Dịch vụ tiện ích'), yAxisType: "left", unit: "%" },
                ],
                line: [],
                area: [],
            },
        },
        'changePercent':{
            key: 'cashflow',
            chartType: "composed",
            title: `${t('% tăng/giảm dòng tiền theo ngành')}`,
            data: fakeData[dataType],
            timeType: timeType,
            YAxisUnit: "%",
            barColorByValue: true,
            chartStructure: {
                bar: [],
                line: [
                    { dataKey: "bank", name: t('Ngân hàng'), yAxisType: "left", unit: "%" },
                    { dataKey: "estate", name: t('Bất động sản'), yAxisType: "left", unit: "%" },
                    { dataKey: "finance", name: t('Dịch vụ tài chính'), yAxisType: "left", unit: "%" },
                    { dataKey: "energy", name: t('Tài nguyên'), yAxisType: "left", unit: "%" },
                    { dataKey: "retail", name: t('Dịch vụ bán lẻ'), yAxisType: "left", unit: "%" },
                    { dataKey: "food", name: t('Thực phẩm & đồ uống'), yAxisType: "left", unit: "%" },
                    { dataKey: "contruct", name: t('Xây dựng & vật liệu'), yAxisType: "left", unit: "%" },
                    { dataKey: "chemistry", name: t('Hóa chất'), yAxisType: "left", unit: "%" },
                    { dataKey: "petroleum", name: t('Dầu khí'), yAxisType: "left", unit: "%" },
                    { dataKey: "industrial", name: t('Hàng hóa & dịch vụ'), yAxisType: "left", unit: "%" },
                    { dataKey: "technology", name: t('Công nghệ'), yAxisType: "left", unit: "%" },
                    { dataKey: "services", name: t('Dịch vụ tiện ích'), yAxisType: "left", unit: "%" },
                ],
                area: [],
            },
        },
    }

    const changeTimetype = (value) => {
        setTimeType(value)
    }
    const dayChange = {
        right: <Select
                    size="small"
                    defaultValue="current"
                    className=" ml-2"
                    style={{ width: 130 }}
                    onChange={changeTimetype}
                    value={timeType}
                >
                    <Select.Option value="current">Trong phiên</Select.Option>
                    <Select.Option value="day">Ngày</Select.Option>
                    <Select.Option value="week">Tuần</Select.Option>
                </Select>
    }

    return (
        <div className="overflow-y-auto px-1" style={{ height: glContainer.height }}>
            <Tabs tabBarExtraContent={dayChange}>
                <Tabs.TabPane key={data[dataType].key} tab={data[dataType].title}>
                    <Radio.Group
                        // value={heatMapType}
                        onChange={onChangeStockType}
                        size="small"
                        buttonStyle="solid"
                        className="mb-1"
                    >
                        <Radio.Button className="mr-1 font-bold" value="totalTradeValue">{t('Giá trị')}</Radio.Button>
                        <Radio.Button className="mr-1 font-bold" value="totalTrade">{t('Tỷ trọng')}</Radio.Button>
                        <Radio.Button className="mr-1 font-bold" value="changePercent">{t('% tăng/giảm')}</Radio.Button>
                    </Radio.Group>
                    <ChartCard
                        title={data[dataType].title}
                        width={chartWidth}
                        height={chartHeight}
                        data={data[dataType].data}
                        timeType={data[dataType].timeType}
                        chartType={data[dataType].chartType}
                        chartStructure={data[dataType].chartStructure}
                        YAxisUnit={data[dataType].YAxisUnit}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default CashFlowContainer